import React, { Component, Fragment, lazy, Suspense } from 'react'
import { Switch, Route, withRouter, useLocation, Link } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import {
  PAYER_TRANSFERS_PATH,
  PAYER_ORDERS_PATH,
  PAYER_PROFILE_PATH,
  PAYER_COURSE_SESSIONS_PATH,
  PAYER_MEMBERSHIP_SESSIONS_PATH,
  PAYER_BANK_ACCOUNTS_PATH,
  PAYER_COMMUNITIES_PATH,
  PAYER_INVOICES_PATH,
  PAYER_CREDIT_MEMOS_PATH,
} from 'constants/routes/payer.constants'
import { profile } from 'utils/profileHelper.utils'

import LoadingFallback from 'ui/loading-fallback/LoadingFallback'
import { Page } from 'shared/components/side-menu/Page'

const BankAccountsList = lazy(() => import('./bank-accounts/BankAccountsList'))
const ProfileForm = lazy(() => import('./profile/ProfileForm'))
const PayerCommunitiesFeedPosts = lazy(() => import('./community/PayerCommunitiesFeedPosts'))
const OrdersList = lazy(() => import('./orders/OrdersList'))
const CourseSessionsList = lazy(() => import('./course-sessions/CourseSessionsList'))
const MembershipSessionsList = lazy(() => import('./membership-sessions/MembershipSessionsList'))
const TransfersList = lazy(() => import('./transfers/TransfersList'))
const InvoicesReversalsList = lazy(() => import('./invoicesReversalsList/InvoicesReversalsList'))
const CreditMemosList = lazy(() => import('./credit-memos/CreditMemosList'))

const PageSideMenu = ({
  domainLogo,
  profileStore,
  profileType,
  hideBurgerMenu,
  isCopyrightVisible,
  currenciesStore,
  userStore,
  sellerStore,
  publisherStore,
  eloPublisherStore,
  teamMemberStore,
  payerStore,
  paymentSettingStore,
}) => {
  const { pathname } = useLocation()

  return (
    <Page.SideMenu
      domainLogo={domainLogo}
      profileStore={profileStore}
      profileType={profileType}
      hideBurgerMenu={hideBurgerMenu}
      isCopyrightVisible={isCopyrightVisible}
      pathname={pathname}
      LinkComponent={Link}
      currenciesStore={currenciesStore}
      userStore={userStore}
      sellerStore={sellerStore}
      publisherStore={publisherStore}
      eloPublisherStore={eloPublisherStore}
      teamMemberStore={teamMemberStore}
      payerStore={payerStore}
      paymentSettingStore={paymentSettingStore}
    />
  )
}

@withRouter
@inject(
  'courseViewsStore',
  'sellersStore',
  'payerStore',
  'currenciesStore',
  'userStore',
  'sellerStore',
  'publisherStore',
  'eloPublisherStore',
  'teamMemberStore',
  'paymentSettingStore'
)
@observer
class PayerResolver extends Component {
  componentDidMount() {
    this.props.sellersStore.fetchFullList()
  }

  render() {
    const {
      courseViewsStore,
      sellersStore,
      payerStore,
      currenciesStore,
      userStore,
      sellerStore,
      publisherStore,
      eloPublisherStore,
      teamMemberStore,
      paymentSettingStore,
    } = this.props
    const { courseThemeData } = courseViewsStore
    const {
      item: { domainLogo, membershipThemeId },
      isCopyrightVisible,
    } = sellersStore
    const hideBurgerMenu = membershipThemeId || courseThemeData.id

    return (
      <Fragment>
        <PageSideMenu
          domainLogo={domainLogo || sellersStore.detectedDomainLogo}
          profileStore={payerStore}
          profileType={profile.profileType}
          hideBurgerMenu={hideBurgerMenu}
          isCopyrightVisible={isCopyrightVisible}
          currenciesStore={currenciesStore}
          userStore={userStore}
          sellerStore={sellerStore}
          publisherStore={publisherStore}
          eloPublisherStore={eloPublisherStore}
          teamMemberStore={teamMemberStore}
          payerStore={payerStore}
          paymentSettingStore={paymentSettingStore}
        />
        <Page.Content>
          <Suspense fallback={<LoadingFallback type='payer_resolver' />}>
            <Switch>
              <Route exact path={PAYER_TRANSFERS_PATH} component={TransfersList} />
              <Route exact path={PAYER_ORDERS_PATH} component={OrdersList} />
              <Route exact path={PAYER_COURSE_SESSIONS_PATH} component={CourseSessionsList} />
              <Route exact path={PAYER_MEMBERSHIP_SESSIONS_PATH} component={MembershipSessionsList} />
              <Route exact path={PAYER_INVOICES_PATH} component={InvoicesReversalsList} />
              <Route exact path={PAYER_CREDIT_MEMOS_PATH} component={CreditMemosList} />
              <Route exact path={PAYER_PROFILE_PATH} component={ProfileForm} />
              <Route exact path={PAYER_BANK_ACCOUNTS_PATH} component={BankAccountsList} />
              <Route exact path={PAYER_COMMUNITIES_PATH} component={PayerCommunitiesFeedPosts} />
            </Switch>
          </Suspense>
        </Page.Content>
      </Fragment>
    )
  }
}

PayerResolver.displayName = 'PayerResolver'

export default PayerResolver
