import { computed, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/sellerFonts.api'

import { getFontFamilyOptions } from '../../../utils/fonts.utils'

export class FontsStore extends SharedStore {
  storeName = 'FontsStore'
  childApi = api // hack to make api visible in abstract store

  constructor() {
    super()

    makeObservable(this)
  }

  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promise.then() to do after-actions

  /**
   * Returns Font Family options for select picker.
   */
  @computed get fontsOptions() {
    return getFontFamilyOptions(this.list || [], false)
  }

  /**
   * Returns Font Family options for Froala Editor.
   */
  @computed get froalaFonts() {
    return getFontFamilyOptions(this.list || [], true)
  }
}
