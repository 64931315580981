import { GET } from 'utils/requests.utils'

import { PaginatedResponse } from 'types/responses'
import { Nullable } from 'types/helpers'
import { SellerBuyerData } from '../types/common'

const BASE_URL = '/payer/credit_memos'

interface CredibleDataBackedTransfer {
  transferable: {
    invoice: {
      number: string
    }
  }
}

interface CredibleDataTransfer {
  form: string
  total: number
  vatAmount: number
  creditCard: {
    id: number
    exp: string
    num: string
    auth: null
    brand: string
    extId: string
    userId: number
    provider: string
    createdAt: string
    errorData: Record<string, unknown>
    updatedAt: string
    countryCode: string
    providerData: Record<string, unknown>
  }
  totalWoVat: number
}

interface CredibleData {
  transfer: CredibleDataTransfer
  backedTransfer: CredibleDataBackedTransfer
}

export interface CreditMemo {
  id: number
  backedTransfer: Nullable<string>
  credibleData: CredibleData
  credibleId: Nullable<number>
  credibleType: Nullable<string>
  docVersion: string
  edited: boolean
  forMonthlyPayment: boolean
  form: string
  invoiceId: Nullable<number>
  invoiceType: Nullable<string>
  number: string
  orderId: number
  product: {
    id: number
    name: string
  }
  resellerId: Nullable<number>
  sellerBuyerData: SellerBuyerData
  sellerId: number
  token: string
  transfer: Nullable<Record<string, unknown>>
  type: Nullable<string>
  vatRate: Nullable<string>
  createdAt: string
  updatedAt: string
  order: {
    id: number
    payerId: number
  }
}

export const fetchList = (data): Promise<PaginatedResponse<CreditMemo[]>> => GET(BASE_URL, data)
