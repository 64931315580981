import { GET, POST, DELETE } from 'utils/requests.utils'

const BASE_URL = '/payer/covers'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id) => GET(`${BASE_URL}/${id}`)

export const createItem = (data) => POST(BASE_URL, data)

export const deleteItem = (id) => DELETE(`${BASE_URL}/${id}`)
