import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'

import * as api from '../api/creditMemos.api'
import { CreditMemo } from '../api/creditMemos.api'

export class CreditMemosStore extends SharedStore<CreditMemo> {
  storeName = 'CreditMemosStore'
  childApi = api

  constructor() {
    super()

    makeObservable(this)
  }
}
