import { action, makeObservable, override } from 'mobx'

import { notify } from 'libs/common/notify'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/courseSessions.api'

export class CourseSessionsStore extends SharedStore {
  storeName = 'CourseSessionsStore'
  childApi = api

  @action cancelSubscription = async (id) => {
    this.loading = true

    const resp = await api.cancelItemSubscription(id)
    if (resp.success) {
      notify('success', I18n.t('react.shared.renew.messages.unsubscribeSuccess'))
      await this.fetchList()
    }

    this.loading = false
  }

  @action continueSubscription = async (id) => {
    this.loading = true

    const resp = await api.continueItemSubscription(id)

    if (resp.success) {
      notify('success', I18n.t('react.shared.renew.messages.success'))
      await this.fetchList()
    }

    this.loading = false
  }

  @action dripInToggle = async (id) => {
    this.loading = true

    const resp = await api.dripInToggle(id)

    if (resp.success) {
      notify('success', I18n.t('react.cabinet.notific.success'))
      this.fetchList()
    }

    this.loading = false
  }

  @override
  async fetchItem(id, data = {}) {
    this.loading = true

    const resp = await api.fetchItem(id, {
      // expand: toJS(this.expands),
      ...data,
    })
    this.item = resp.data || {}

    this.loading = false

    return resp
  }

  constructor() {
    super()

    makeObservable(this)

    this.loading = true
  }
}
