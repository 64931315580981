import { GET, POST, PATCH, DELETE } from 'utils/requests.utils'

const BASE_URL = (courseSessionId, lessonId) => `/payer/course_sessions/${courseSessionId}/lessons/${lessonId}/comments`

export const fetchList = (data) => GET(BASE_URL(data.courseSessionId, data.lessonId), data)

export const createItem = (data) => POST(BASE_URL(data.courseSessionId, data.lessonId), data)

export const updateItem = (id, data, params) =>
  PATCH(`${BASE_URL(data.courseSessionId, data.lessonId)}/${id}`, {
    ...data,
    ...params,
  })

export const deleteItem = (id, data) => DELETE(`${BASE_URL(data.courseSessionId, data.lessonId)}/${id}`)
