import { action, computed, toJS, makeObservable, override } from 'mobx'

import { getElopageConfig } from 'utils/elopageConfig.utils'
import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/sellers.api'

export class SellersStore extends SharedStore {
  storeName = 'SellersStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  isAppActive = (key, id) => {
    const { optionKeys } = (id ? this.list.find((item) => item.id === id) : this.item) || {}

    return optionKeys && optionKeys.includes(key)
  }

  @action setCustomLoading = (customLoading) => (this.customLoading = customLoading)

  @computed get detectedDomainLogo() {
    const { host } = window.location
    const webHost = getElopageConfig('webHost')
    const domainName = host !== webHost ? host : null

    // if custom domain
    if (domainName) {
      return (this.list.find((s) => s.domainName === domainName) || {}).domainLogo
    }

    // if only one seller
    if (this.list.length === 1) {
      return (this.list[0] || {}).domainLogo
    }

    return null
  }

  @computed get isCopyrightVisible() {
    const { host = null } = this.item || {}
    const isWhitelabel = this.isAppActive('elopage-badge')
    const webHost = getElopageConfig('webHost')
    const isCustomDomain = host !== webHost

    return !(isWhitelabel || isCustomDomain)
  }

  @override
  async fetchList(data) {
    if (!data?.hideLoading) {
      this.toggleLoading(true)
    }
    const resp = await this.childApi.fetchList({
      expand: toJS(this.expands),
      ...this.queryParams,
      ...data,
    })
    this.processFetchListResponse(resp)

    this.toggleLoading(false)
    return resp
  }

  constructor() {
    super()

    makeObservable(this)

    this.customLoading = true
  }
}
