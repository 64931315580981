import * as Yup from 'yup'

import {
  MIN_NAME_LENGTH,
  MIN_PASSWORD_LENGTH,
  MIN_USERNAME_LENGTH,
  MAX_USERNAME_LENGTH,
  PASSWORD_ALLOWED_SYMBOLS,
} from 'constants/profile.constants'

import { PHONE_NUMBER_REGEX } from '@elo-kit/constants/regex.constants'
import { MAIL_REGEX, USERNAME_REGEX } from 'constants/regex.constants'
import * as api from '../containers/app/api/user.api'

export const notEmptyEmailValidator = Yup.string()
  .email(I18n.t('react.shared.validations.email_invalid'))
  .required(I18n.t('react.shared.validations.empty'))
  .matches(MAIL_REGEX, I18n.t('react.shared.validations.email_invalid'))

export const usernameValidator = Yup.string()
  .min(MIN_USERNAME_LENGTH, I18n.t('react.shared.validations.username_too_small'))
  .max(MAX_USERNAME_LENGTH, I18n.t('react.shared.validations.username_too_big'))
  .matches(USERNAME_REGEX, I18n.t('react.shared.validations.username_invalid'))
  .required(I18n.t('react.shared.validations.empty'))

export const passwordValidator = Yup.string()
  .min(
    MIN_PASSWORD_LENGTH,
    I18n.t('react.shared.validations.password_too_short', {
      min: MIN_PASSWORD_LENGTH,
    })
  )
  .required(I18n.t('react.shared.validations.empty'))

export const complexPasswordValidator = Yup.string()
  .test({
    name: 'validatePassword',
    message: I18n.t('react.shared.validations.password_is_invalid'),
    test: (password) => validatePassword(password, passwordRules).isPasswordValid,
  })
  .required(I18n.t('react.shared.validations.empty'))

export const notEmptyValidator = Yup.string().required(I18n.t('react.shared.validations.empty'))

export const notEmptyCityDataValidator = Yup.string().required(I18n.t('react.shared.validations.empty'))

export const nameValidator = notEmptyValidator.min(
  MIN_NAME_LENGTH,
  I18n.t('react.shared.validations.name_too_short', { min: MIN_NAME_LENGTH })
)

export const notEmptyNumberWithMessage = Yup.number().typeError(' ').required(I18n.t('react.shared.validations.empty'))

export const notEmptyNumber = Yup.number().typeError(' ').required(' ')

export const notFalsyBoolean = Yup.bool().oneOf([true], ' ')

export const notPhoneNumberValidator = Yup.string()
  .matches(PHONE_NUMBER_REGEX, I18n.t('react.shared.validations.phone_number_invalid'))
  .required(I18n.t('react.shared.validations.empty'))

export const notEmptyObjectValidator = Yup.object()

const VALIDATION_INTERVAL_IN_MILISECONDS = 500

export const validateEmailUniqueness = getDelayedValidator(async function (email) {
  if (email) {
    const response = await api.checkEmailUniqueness(email)
    let result

    if (response.exist) {
      result = I18n.t('react.shared.validations.email_exists')
    }

    return result
  }
  return I18n.t('react.shared.validations.empty')
})

function getDelayedValidator(callback) {
  let prevValue
  let prevResult
  let enqueuedValidationId

  return async function (value, isTouched) {
    clearTimeout(enqueuedValidationId)

    if (!value && isTouched) {
      return I18n.t('react.shared.validations.empty')
    }

    if (prevValue === value) {
      return prevResult
    }
    await new Promise((resolve) => {
      enqueuedValidationId = setTimeout(() => resolve(), VALIDATION_INTERVAL_IN_MILISECONDS)
    })

    prevValue = value

    prevResult = await callback(value)
    return prevResult
  }
}

export const passwordRules = [
  {
    regex: new RegExp(`.{${MIN_PASSWORD_LENGTH}}`),
    name: 'length',
  },
  {
    regex: /[0-9]/g,
    name: 'numbers',
  },
  {
    regex: new RegExp(`[${PASSWORD_ALLOWED_SYMBOLS}]`),
    name: 'symbols',
  },
  {
    method: (text) => {
      if (!text) {
        return false
      }
      return text.toLowerCase() !== text && text.toUpperCase() !== text
    },
    name: 'upperLowerCase',
  },
]

export const validatePassword = (password = '', rules) => {
  const confirmedRules = []

  rules.forEach((rule) => {
    if ((rule.regex && password.match(rule.regex)) || (rule.method && rule.method(password))) {
      confirmedRules.push(rule.name)
    }
  })

  return {
    confirmedRules,
    isPasswordValid: confirmedRules.length === rules.length,
  }
}
