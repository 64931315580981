import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Formik, Form, Field } from 'formik'
import { observable, action } from 'mobx'

import FTextField from 'ui/formik-form/FTextField'
import { PasswordFieldWithComplexity } from 'ui/password-complexity/PasswordFieldWithComplexity'
import { LoadingSpinner } from '@elo-kit/components/loading-spinner/LoadingSpinner'
import { WarningInfo } from '@elo-kit/components/elo-ui/warning-info/WarningInfo'
import { EloButton } from '@elo-kit/components/elo-button/EloButton'
import EloModal from 'ui/EloModal'

import { TEXT_FIELD_TYPE } from 'constants/forms.constants'

import { createValidationSchema, getValidPasswordField } from 'utils/validators.utils'
import { complexPasswordValidator } from 'utils/validators-auth.utils'
import './change-user-credential-modal.scss'

const DEFAULT_CURRENT_PASSWORD = 'defaultpassword'

@observer
class ChangePasswordModal extends Component {
  @observable isPasswordFocus

  submitForm = async (values, { setFieldError, setSubmitting }) => {
    const { toggle, userStore } = this.props
    const { item: user, updateItem } = userStore
    const requestData = {
      password: values.newPassword,
      passwordConfirmation: values.newPasswordConfirmation,
    }

    if (!user.noPassword) {
      requestData.currentPassword = values.currentPassword
    }

    const { success } = await updateItem(requestData)

    if (success) {
      toggle()
    } else {
      setFieldError('currentPassword', I18n.t('react.shared.validations.password_is_invalid'))
      setSubmitting(false)
    }
  }

  @action setIsPasswordFocus = (value) => (this.isPasswordFocus = value)
  render() {
    const {
      isOpen,
      toggle,
      userStore: { item: user },
    } = this.props
    const { loading, noPassword } = user
    const initialValues = {
      currentPassword: noPassword ? DEFAULT_CURRENT_PASSWORD : '',
    }

    const validationSchema = createValidationSchema({
      currentPassword: getValidPasswordField(),
      newPassword: complexPasswordValidator,
      newPasswordConfirmation: getValidPasswordField().test(
        'new-passwords-matching',
        I18n.t('react.shared.validations.not_identical'),
        function (newPasswordConfirmation) {
          const { newPassword } = this.options.parent

          return newPasswordConfirmation === newPassword
        }
      ),
    })

    return (
      <EloModal
        cancel={false}
        className='change-user-credential-modal'
        title={noPassword ? I18n.t('react.shared.setup_password') : I18n.t('react.shared.change_password')}
        {...{
          isOpen,
          toggle,
        }}
      >
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.submitForm}>
          {({ values, isSubmitting, isValid }) => {
            const submitDisabled = isSubmitting || !isValid
            return loading ? (
              <LoadingSpinner />
            ) : (
              <Form>
                {noPassword ? (
                  <WarningInfo
                    message={I18n.t('react.cabinet.profile.edit_pass.no_password')}
                    className='warning-info--no-margin-top'
                  />
                ) : (
                  <Field
                    required
                    prefixNormalField
                    name='currentPassword'
                    component={FTextField}
                    type={TEXT_FIELD_TYPE.password}
                    placeholder={I18n.t('react.shared.current_password')}
                    label={I18n.t('react.shared.enter_current_password')}
                    prefix={<i className='fas fa-lock prefix-icon' />}
                  />
                )}
                <PasswordFieldWithComplexity
                  password={values.newPassword}
                  fieldPrefix={<i className='fas fa-lock prefix-icon' />}
                  fieldName='newPassword'
                  fieldPlaceholder={I18n.t('react.shared.enter_new_password')}
                  fieldLabel={I18n.t('react.shared.new_password')}
                />
                <Field
                  required
                  withHidePassword
                  prefixNormalField
                  name='newPasswordConfirmation'
                  component={FTextField}
                  type={TEXT_FIELD_TYPE.password}
                  placeholder={I18n.t('react.shared.re_enter_new_password')}
                  label={I18n.t('react.shared.re_enter_new_password')}
                  prefix={<i className='fas fa-lock prefix-icon' />}
                />

                <div className='elo-modal__bottom-buttons elo-modal__bottom-buttons--centered'>
                  <EloButton outlined onClick={toggle}>
                    {I18n.t('react.shared.button.cancel')}
                  </EloButton>
                  <EloButton type='submit' disabled={submitDisabled}>
                    {I18n.t('react.shared.button.save')}
                  </EloButton>
                </div>
              </Form>
            )
          }}
        </Formik>
      </EloModal>
    )
  }
}

ChangePasswordModal.displayName = 'ChangePasswordModal'

export default ChangePasswordModal
