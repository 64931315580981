import { action, computed, makeObservable } from 'mobx'

import { REGISTRATION_STEPS } from 'constants/registration.constants'
import { PRIVACY_FORMS } from 'constants/privacyPolicy.constants'

import { postTrafficCookies } from 'shared/api/trafficSourceCookies.api'
import { getCookies } from 'libs/common/cookies'
import SharedStore from 'shared/stores/shared.store'

import * as api from 'shared/api/seller.api'
import * as ssApi from '../../cabinet/api/sellerSetting.api'
import { PayerRootStore } from './payerRoot.store'

export class SellerStore extends SharedStore<api.Seller> {
  storeName = 'SellerStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promise.then() to do after-actions
  root: PayerRootStore

  isAppActive = (key) => {
    const { optionKeys } = this.item
    return optionKeys && optionKeys.includes(key)
  }

  @action setAppKey = (app, val) => {
    if (val) {
      this.item.optionKeys.push(app)
    }
  }

  @action createWistiaProject = async () => {
    const resp = (await ssApi.createWistiaProject()) as any
    this.item.wistiaProjectId = resp.wistiaProjectId
  }

  constructor(rootStore: PayerRootStore) {
    super()
    this.root = rootStore
    makeObservable(this)
  }

  @computed get registrationStates() {
    const { confirmed } = this.root?.userStore.item
    const { profileDone, imprintDone, paymentAccountDone, privacyForm, vatLiabilityText } = this.item

    return {
      [REGISTRATION_STEPS.verifyEmail]: confirmed,
      [REGISTRATION_STEPS.imprint]: imprintDone,
      [REGISTRATION_STEPS.profile]: profileDone,
      [REGISTRATION_STEPS.invoiceSetup]: vatLiabilityText && vatLiabilityText.length > 0,
      [REGISTRATION_STEPS.privacyPolicy]: privacyForm && privacyForm !== PRIVACY_FORMS.none,
      [REGISTRATION_STEPS.paymentSettings]: paymentAccountDone,
    }
  }

  async logTrafficCookies() {
    const cookies = getCookies('initialTrafficSource', false)

    if (!cookies) {
      return
    }

    if (!this.item.id) {
      this.setExpands(['user_profile'])
      await this.fetchItem()
    }

    postTrafficCookies({ value: cookies, profile_id: this.item.id })
  }
}
