import { action, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/lessons.api'

export class LessonsStore extends SharedStore {
  storeName = 'LessonsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @action
  finishLesson = async (lessonId, data) => {
    this.loading = true
    const resp = await api.finishItem(lessonId, data)
    this.item = resp.data
    this.loading = false
    return resp
  }

  constructor() {
    super()

    makeObservable(this)
  }
}
