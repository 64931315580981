import { computed, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/orders.api'

export class OrdersStore extends SharedStore {
  storeName = 'OrdersStore'
  childApi = api

  cancelPS = async (orderId) => {
    this.toggleLoading(true)

    await api.cancelOrder(orderId)

    this.toggleLoading(false)
  }

  continueOrder = async (orderId) => {
    this.toggleLoading(true)

    await api.continueOrder(orderId)

    this.toggleLoading(false)
  }

  @computed get changedOrders() {
    return this.list.filter((order) => {
      if (!order || !order.appliedVatChange) return false
      if (order.appliedVatChange.payerDecision !== 'pending') return false
      const {
        appliedVatChange: { productsStat: statistic },
      } = order
      return statistic[0].nextToPaySum.after > statistic[0].nextToPaySum.before
    })
  }

  constructor() {
    super()

    makeObservable(this)
  }
}
