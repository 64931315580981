import { ELO_GTM_CODES } from 'constants/general.constants'
import { isProduction, isStaging } from 'utils/env.utils';
import { pushToDataLayer, isDataLayerAvailable } from 'utils/GTMDataLayer.utils';
import { getElopageConfig } from 'utils/elopageConfig.utils'

const env = getElopageConfig('env')

if (isProduction() || isStaging()) {
  ; (function (document, s) {
    const script = document.createElement(s)
    script.innerHTML = `(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      })
      const f = d.getElementsByTagName(s)[0]
      const j = d.createElement(s)
      const dl = l !== 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      if (${isStaging()}) {
        j.src += '&gtm_auth=E1JXYwX5Nn2VlyMouWcY0w&gtm_preview=env-221&gtm_cookies_win=x'
      }
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', '${ELO_GTM_CODES[env]}')`
    script.setAttribute('data-cookieconsent', 'ignore')
    const f = document.getElementsByTagName(s)[0]

    f.parentNode.insertBefore(script, f)
  })(document, 'script')

  if (isDataLayerAvailable()) {
    pushToDataLayer({
      event: 'pageview',
      virtualUrl: window.location.pathname,
    })
    // Google Analytics
  } else if (window.ga !== undefined) {
    ga('set', 'anonymizeIp', true)
    ga('set', 'location', window.location.pathname)
    ga('send', 'pageview')
  } else if (window._gaq !== undefined) {
    _gaq.push(['_trackPageview'])
  } else if (window.pageTracker !== undefined) {
    pageTracker._trackPageview()
  }

  window.gaOptout = function () {
    document.cookie = 'elopage-ga-disable=true; path=/; expires=Thu, 31 Dec 2099 23:59:59 UTC;'
    window.Elopage.Analytics.disableGoogleTracking()
  }

  window.Elopage = window.Elopage || {}
  window.Elopage.Analytics = {
    initHandlers() {
      return $('body').on('click', '[data-track-ga-share]', (el) => {
        const data = $(el.currentTarget).data('track-ga-share').split('_')
        if (isDataLayerAvailable()) {
          return pushToDataLayer({
            event: 'social share',
            type: data[0],
            social: data[1],
          })
        } else if (window.ga) {
          return ga('send', 'event', `Social share ${data[0]}`, data[1])
        }
      })
    },

    disableGoogleTracking() {
      window[`ga-disable-${ga.getAll()[0].get('trackingId')}`] = true
    },

    init() {
      if (document.cookie.indexOf('elopage-ga-disable=true') > -1) {
        this.disableGoogleTracking()
      }
      document.querySelectorAll('.ga-event').forEach((el) => {
        const { category, action, label, value } = el.dataset

        if (isDataLayerAvailable) {
          return pushToDataLayer({
            category,
            action,
            label,
            value,
          })
        } else if (window.ga) {
          return ga('send', 'event', category, action, label, value)
        }
      })
      return this.initHandlers()
    },
  }
}
