import { action, computed, observable, runInAction, makeObservable } from 'mobx'

import { getPayerProductLink } from 'utils/sellables.utils'
import { getObservableMapKeys } from 'utils/helpers.utils'

import { DEFAULT_PRODUCT, getDefaultProductsList } from 'constants/contentPage.constants'
import { ELOPAGE_CABINETS } from '@elo-kit/constants/general.constants'
import { LESSON_STATUSES_PUBLISH_STATES } from 'constants/courses.constants'
import { ACTIVE_PROFILES } from 'constants/profile.constants'

import * as videoCaptionsApi from '../api/videoCaptions.api'

export class ContentPageStore {
  @observable loading = true

  @observable membershipSellable = ''
  @observable membershipViewType = ACTIVE_PROFILES.seller // seller OR product
  @observable membershipProducts = {}
  @observable sellerProducts = getDefaultProductsList()
  @observable popularProducts = []
  @observable product = DEFAULT_PRODUCT
  @observable lessons = []
  @observable buttonProduct = DEFAULT_PRODUCT
  @observable view = ELOPAGE_CABINETS.payer
  @observable videoCodes = new Map()
  @observable videoCaptionsList = new Map()

  constructor(rootStore) {
    this.root = rootStore
    makeObservable(this)
  }

  @computed get affiliateProgramsStore() {
    return this.root?.affiliateProgramsStore
  }

  @action fetchProduct = async (id, data) => {
    this.loading = true
    const resp = await this.root?.productsStore.fetchItem(id, {
      ...data,
      sellerId: this.root?.sellersStore.item.id,
    })
    this.buttonProduct = resp.data
    this.loading = false
    return resp
  }

  @action fetchProducts = async (data) => {
    this.loading = true
    const resp = await this.root?.productsStore.fetchList({
      ...data,
      sellerId: this.root?.sellersStore.item.id,
    })
    this.loading = false
    return resp
  }

  @action fetchPopularProducts = async (data) => {
    const resp = await this.root?.productsStore.fetchFullList({
      ...data,
      sellerId: this.root?.sellersStore.item.id,
      canBeSoldViaShop: true,
    })
    const { list = [] } = resp.data || {}
    this.popularProducts = list
    return resp
  }

  @action setMembershipProducts = (data, blockId) => {
    this.membershipProducts = {
      ...this.membershipProducts,
      [blockId]: {
        ...(this.membershipProducts[blockId] || {}),
        ...data,
      },
    }
  }

  @action fetchMembershipProducts = async (data = {}, blockId) => {
    const { query = '' } = data || {}
    switch (this.membershipViewType) {
      case ACTIVE_PROFILES.seller: {
        this.root?.sellablesStore.scopes.set('sellerId', this.root?.sellersStore.item.id)
        this.root?.sellablesStore.scopes.set('withAccess', true)
        this.setMembershipProducts({ loading: true, query }, blockId)
        const { data: { list = [] } = {} } = await this.root?.sellablesStore.fetchFullList(data)
        this.setMembershipProducts(
          {
            list,
            loading: false,
          },
          blockId
        )
        return list
      }
      case 'product': {
        this.root?.sellableItemsStore.scopes.set('sellableId', this.membershipSellable)
        this.root?.sellableItemsStore.scopes.set('withAccess', true)
        this.setMembershipProducts({ loading: true, query }, blockId)
        const resp = await this.root?.sellableItemsStore.fetchFullList(data)
        const { data: { list = [] } = {} } = resp || {}
        this.setMembershipProducts(
          {
            list: list,
            loading: false,
          },
          blockId
        )
        this.root?.viewLogsStore.trackViewLog({
          actionType: 'payer_sellable',
          sellableId: this.membershipSellable,
          sellerId: this.root?.sellersStore.item.id,
        })
        return list
      }
      default:
        break
    }
  }

  @action setMembershipViewType = (type) => {
    this.membershipViewType = type
  }

  @action setMembershipSellable = (id) => {
    this.membershipSellable = id
  }

  @action handlePaginationChange = () => {}

  setProductsCustomLoading = (value) => this.root?.productsStore.toggleCustomLoading(value)

  createVideoCode = (data) =>
    videoCaptionsApi.createItem({
      ...data,
      lessonStatusId: this.root?.courseViewsStore.activeLessonStatus.id,
    })

  passVideoCode = (id, data) =>
    videoCaptionsApi.updateItem(id, {
      ...data,
      lessonStatusId: this.root?.courseViewsStore.activeLessonStatus.id,
    })

  fetchVideoCaptions = async (data) => {
    const resp = await videoCaptionsApi.fetchList({
      ...data,
      lessonStatusId: this.root?.courseViewsStore.activeLessonStatus.id,
      sortKey: 'created_at',
      sortDir: 'asc',
    })
    if (resp.success) runInAction(() => this.videoCaptionsList.set(data.contentBlockId, resp.data.list))
    return resp
  }

  fetchVideoCodes = (data, blockCodes = []) => {
    if (!this.hideVideoCodes) {
      this.fetchVideoCaptions(data).then(() => {
        const videoCaptions = this.videoCaptionsList.get(data.contentBlockId) || []
        blockCodes.forEach((blockCode) => {
          const { value, id, passed } =
            videoCaptions.find((caption) => caption.contentBlockCaptionId === blockCode.id) || {}
          const codeData = {
            code: value,
            id,
            passed,
            name: blockCode.name,
          }
          if (passed) codeData.value = value
          this.setVideoCode(data.contentBlockId, blockCode.id, codeData)
        })
      })
    }
  }

  @action setVideoCode = (contentBlockId, blockCodeId, newBlockCodeData) => {
    const blockVideoCodes = this.videoCodes.get(contentBlockId)
    const blockCodeData = this.getVideoCodeData(contentBlockId, blockCodeId)
    this.videoCodes.set(contentBlockId, {
      ...blockVideoCodes,
      [blockCodeId]: {
        ...blockCodeData,
        ...newBlockCodeData,
      },
    })
  }

  getVideoCodeData = (contentBlockId, blockCodeId) => (this.videoCodes.get(contentBlockId) || {})[blockCodeId] || {}

  @action setVideoCodeValue = (contentBlockId, blockCodeId, value) => {
    const codeData = this.getVideoCodeData(contentBlockId, blockCodeId)
    const passed = String(codeData.code) === String(value)
    this.setVideoCode(contentBlockId, blockCodeId, {
      value,
      passed,
    })
    const codeCaption = this.videoCaptionsList.get(contentBlockId).find((caption) => caption.id === codeData.id)

    if (passed && !codeCaption.passed) {
      this.passVideoCode(codeData.id, { contentBlockId }).then(() => this.fetchVideoCaptions({ contentBlockId }))
    }
  }

  @action deleteBlockVideoCodes = (blockId) => this.videoCodes.delete(blockId)

  @computed get allVideoCodesValid() {
    let isValid = true
    if (this.videoCodes.size || !this.hideVideoCodes) {
      const blockIds = getObservableMapKeys(this.videoCodes)
      blockIds.forEach((blockId) => {
        const videoCodes = this.videoCodes.get(blockId) || {}
        for (const blockCodeId in videoCodes) {
          if (!videoCodes[blockCodeId].passed) {
            isValid = false
          }
        }
      })
    }
    return isValid
  }

  @computed get seller() {
    return this.root?.sellersStore.item
  }

  @computed get activeLessonStatusId() {
    return this.root?.courseViewsStore.activeLessonStatus.id
  }

  @computed get themePages() {
    return this.root?.membershipThemesStore.item.themePages
  }

  @computed get courseThemesStore() {
    return this.root?.courseThemesStore
  }

  @computed get courseThemeType() {
    return this.root?.courseViewsStore.courseThemeData.form
  }

  isAppActive = (key) => this.root?.sellersStore.isAppActive(key)

  isCourseRefreshAppActive = (id) => this.root?.sellersStore.isAppActive('course_refresh', id)

  getProductLink = (product) => getPayerProductLink(product, this.root?.sellersStore.item.username)

  @computed get hideVideoCodes() {
    const { static: lessonIsStatic } = this.root?.lessonsStore.item || {}
    const lessonIsFinished =
      this.root?.courseViewsStore.activeLessonStatus.publishState === LESSON_STATUSES_PUBLISH_STATES.finished
    return lessonIsStatic || lessonIsFinished || !this.isAppActive('video_captions')
  }
}
