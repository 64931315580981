import type { ApiClientV2Default } from 'utils/api-client/api-client.utils'

import { FromSwagger } from 'types/helpers'
import { CountryAttributes, StateAttributes } from 'types/__generated__/api'

export type Country = FromSwagger<CountryAttributes>
export type State = FromSwagger<StateAttributes>

const BASE_URL = 'common/countries'

export const createCountriesApi = ({ GET }: ApiClientV2Default) => ({
  fetchList: () => GET<Country[]>(BASE_URL),

  fetchItem: (id: number) => GET<Country>(`${BASE_URL}/${id}`),

  fetchStates: (id: string) => GET<State[]>(`${BASE_URL}/${id}/states`),
})

export type CountriesApi = ReturnType<typeof createCountriesApi>
