import { action, observable, makeObservable } from 'mobx'
import { ACCESS_LIMITED_MODAL_MODE } from 'constants/courses.constants'
import { PRODUCT_TYPE_IDS } from 'constants/products.constants'
import { ACCESS_SESSION_LIMIT } from 'constants/options.constants'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/sellables.api'

export class ProductSessionStore extends SharedStore {
  storeName = 'ProductSessionStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @observable accessDeniedModalIsOpen = false
  @observable showAccessLimitedModalMode = ACCESS_LIMITED_MODAL_MODE.anotherPersonViewing
  accessLimitIntervalId = null

  @action setShowAccessLimitedModalMode = (mode) => (this.showAccessLimitedModalMode = mode)
  @action clearIntervalAccessLimitIntervalId = () => {
    clearInterval(this.accessLimitIntervalId)
    this.accessLimitIntervalId = null
  }
  @action toggleAccessDeniedModal = (flag) => (this.accessDeniedModalIsOpen = flag)
  @action closeAccessDeniedModal = (username, historyPush, sellableId, form) => {
    if (form === PRODUCT_TYPE_IDS.membership) {
      historyPush(`/payer/s/${username}/products/${sellableId}`)
    } else {
      historyPush(`/payer/s/${username}`)
    }
    this.toggleAccessDeniedModal(false)
  }
  isNeedCheckAccess = (username) => {
    const {
      item: { accessSessionLimit },
    } = this.root?.sellablesStore
    const currentSeller = this.root?.sellersStore.list.find((s) => s.username === username) || {}
    return (
      currentSeller.optionKeys?.includes(ACCESS_SESSION_LIMIT) && accessSessionLimit && !this.accessDeniedModalIsOpen
    )
  }

  checkAccess = (id) => api.checkAccess(id)

  constructor(rootStore) {
    super()

    this.root = rootStore
    makeObservable(this)
  }
}
