import { Nullable } from 'types/helpers'
import { HTTPResponse, Response } from 'types/responses'
import { GET, PUT } from 'utils/requests.utils'
import { UserProfile } from '../../admin/api/userProfiles.api'
import { ProfileDeletion } from '../types/profile-deletion'

const BASE_URL = '/common/seller'

export interface Seller {
  id: number
  username: string
  acContactSynced: boolean
  activeExternalConsent: string
  allowInvoices: boolean
  allowedCurrencies: string[]
  allowedPaymentMethods: Record<string, string[]>
  applePayEnabled: boolean
  availablePaymentMethods: Record<string, string[]>
  bankAccountId: Nullable<number>
  canSell: boolean
  cashoutsBlocked: boolean
  clicksRefLink: number
  consentForm: string
  contactStateId: number
  contactStateUpdatedAt: Nullable<string>
  contractSigned: Nullable<boolean>
  cookiebotId: Nullable<string>
  cookiebotMode: Nullable<string>
  creditMemoNumber: Nullable<unknown>
  dateOfDebt: Nullable<string>
  deletionState: Nullable<string> | Nullable<number>
  deletionRequest: Nullable<ProfileDeletion>
  directDebitUkEnabled: boolean
  documentConfiguration: SellerDocumentConfiguration
  ecLegitimated: Nullable<boolean>
  ecLegitimationActive: Nullable<boolean>
  eloPublisherId: Nullable<number>
  euDisputeResolution: Nullable<string>
  firstLegitimationNotificationSent: boolean
  googlePayEnabled: boolean
  host: string
  imprintAdditionalInfo: Nullable<string>
  imprintDone: boolean
  imprintUserProfileId: Nullable<number>
  invoiceAdditionalInfo: Nullable<string>
  invoiceNumber: string
  lastLoginAt: string
  legalFormId: Nullable<number>
  legitimated: Nullable<boolean>
  logo: SellerLogo
  logoContentType: Nullable<string>
  logoCropH: Nullable<number>
  logoCropW: Nullable<number>
  logoCropX: Nullable<number>
  logoCropY: Nullable<number>
  logoFileName: Nullable<string>
  logoFileSize: Nullable<number>
  logoRemoteUrl: Nullable<string>
  logoUpdatedAt: Nullable<string>
  lwLegitimated: Nullable<unknown>
  mailerFromEmail: Nullable<unknown>
  mailerFromName: Nullable<unknown>
  managementInformation: Nullable<string>
  monthlyPaymentCmNumber: Nullable<unknown>
  mpLegitimated: Nullable<boolean>
  mpLegitimationActive: Nullable<boolean>
  onTrialPeriod: boolean
  optionKeys: string[]
  paymentAccountDone: Nullable<boolean>
  paymentSettingId: number
  paypalEmail: Nullable<string>
  planAddition: number
  planAdditionJsonb: Record<string, unknown>
  planId: number
  planName: string
  planUsed: string
  policyAccepted: boolean
  possiblePaymentMethods: Record<string, string[]>
  powerSeller: boolean
  privacyDone: boolean
  privacyForm: string
  profileDone: boolean
  protocol: string
  productsCount: number
  registrationProgress: number
  reminded2At: Nullable<string>
  remindedAt: Nullable<string>
  resellerId: Nullable<number>
  secondLegitimationNotificationSent: boolean
  sectorId: Nullable<number>
  sellerClass: string
  sellerLimitReached: boolean
  sellingForm: string
  sepaEnabled: boolean
  showCashout: boolean
  showPayPlanReminder: boolean
  showPayout: boolean
  showTrialEnding: boolean
  subdomainActive: boolean
  subdomainName: string
  supportEmail: Nullable<string>
  supportFirstName: Nullable<string>
  supportLastName: Nullable<string>
  supportPhone: Nullable<string>
  termsAccepted: boolean
  totalCashOut: string
  totalLwCashOut: string
  totalMpCashOut: string
  trialDuration: number
  trialPeriodEndDate: string
  trusted: boolean
  useCustomVatsForPs: boolean
  userId: number
  userProfileId: number
  userProfile: UserProfile
  vatCountryCode: Nullable<string>
  vatLiabilityText: Nullable<string>
  vatPayerInSw: boolean
  vatRate: number
  wistiaProjectId: Nullable<string>
  wistiaProjectPass: string
  createdAt: string
  updatedAt: string
  user: any
}

interface SellerDocumentConfiguration {
  imprint: boolean
  termsOfBusiness: boolean
  privacy: boolean
}

interface SellerLogo {
  s50?: string
  s200?: string
  original?: string
  name?: string
}

export const fetchItem = (id: string | number, data: any) => GET<Response<Seller>>(BASE_URL, data)

export const updateItem = (data: any) => PUT<HTTPResponse>(BASE_URL, data)
