import { notify } from 'libs/common/notify'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/membershipSessions.api'

export class MembershipSessionsStore extends SharedStore {
  storeName = 'MembershipSessionsStore'
  childApi = api

  cancelSubscription = async (id) => {
    this.toggleLoading(true)

    const resp = await api.cancelItemSubscription(id)
    if (resp.success) {
      notify('success', I18n.t('react.shared.renew.messages.unsubscribeSuccess'))
      await this.fetchList()
    } else {
      notify('warning', I18n.t('react.cabinet.notific.warning'))
    }

    this.toggleLoading(false)
  }

  continueSubscription = async (id) => {
    this.toggleLoading(true)

    const resp = await api.continueItemSubscription(id)

    if (resp.success) {
      notify('success', I18n.t('react.shared.renew.messages.success'))
      await this.fetchList()
    } else {
      notify('error', I18n.t('react.shared.renew.messages.setup_payment_first'))
    }

    this.toggleLoading(false)
  }

  dripInToggle = async (id) => {
    this.toggleLoading(true)

    const resp = await api.dripInToggle(id)

    if (resp.success) {
      notify('success', I18n.t('react.cabinet.notific.success'))
      await this.fetchList()
    } else {
      notify('warning', I18n.t('react.cabinet.notific.warning'))
    }

    this.toggleLoading(false)
  }
}
