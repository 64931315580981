import { action, observable, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/sellableItems.api'

export class SellableItemsStore extends SharedStore {
  storeName = 'SellableItemsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions
  @observable addonsBySessionId = {}
  @observable addonsBySessionIdLoading = {}

  @action fetchAddonBySessionId = (sessionId) => {
    const addons = this.addonsBySessionId[sessionId]

    if (!!addons) {
      return addons
    }

    this.addonsBySessionIdLoading = {
      ...this.addonsBySessionIdLoading,
      [sessionId]: true,
    }

    this.fetchFullList({ membershipSessionId: sessionId }).then(({ data }) => {
      this.addonsBySessionId = {
        ...this.addonsBySessionId,
        [sessionId]: data.list,
      }

      this.addonsBySessionIdLoading = {
        ...this.addonsBySessionIdLoading,
        [sessionId]: false,
      }
    })
  }

  constructor() {
    super()

    makeObservable(this)
  }
}
