import { PAYER_PRODUCTS_PATH } from 'constants/routes/payer.constants'
import { SELLABLE_TYPE_IDS, WINDOW_SIZE_TYPE_IDS, WINDOW_SIZES } from 'constants/sellables.constants'

import { ascendingSort } from './helpers.utils'

export const getSellableIconClasses = (form, className = '') => {
  switch (form) {
    case SELLABLE_TYPE_IDS.digital:
      return {
        icon: 'fas fa-laptop',
        container: `cover-widget__type-icon cover-widget__type-icon--digital ${className}`,
      }
    case SELLABLE_TYPE_IDS.download:
      return {
        icon: 'fas fa-arrow-alt-circle-down',
        container: `cover-widget__type-icon cover-widget__type-icon--download ${className}`,
      }
    case SELLABLE_TYPE_IDS.course:
      return {
        icon: 'fas fa-graduation-cap',
        container: `cover-widget__type-icon cover-widget__type-icon--course ${className}`,
      }
    case SELLABLE_TYPE_IDS.eventTickets:
      return {
        icon: 'fas fa-ticket-alt',
        container: `cover-widget__type-icon cover-widget__type-icon--ticket ${className}`,
      }
    case SELLABLE_TYPE_IDS.membership:
      return {
        icon: 'fas fa-users',
        container: `cover-widget__type-icon cover-widget__type-icon--membership ${className}`,
      }
    case SELLABLE_TYPE_IDS.bundle:
    case SELLABLE_TYPE_IDS.upsell:
      return {
        icon: 'fas fa-boxes',
        container: `cover-widget__type-icon cover-widget__type-icon--upsell ${className}`,
      }
    case SELLABLE_TYPE_IDS.licenseKey:
      return {
        icon: 'fas fa-key',
        container: `cover-widget__type-icon cover-widget__type-icon--license_key ${className}`,
      }
    case SELLABLE_TYPE_IDS.certificate:
      return {
        icon: 'fas fa-certificate',
        container: `cover-widget__type-icon cover-widget__type-icon--certificate ${className}`,
      }
    default:
      return
  }
}

export const getScreenSizeKey = () => {
  if (window.innerWidth <= WINDOW_SIZES.medium) {
    return WINDOW_SIZE_TYPE_IDS.medium
  }

  if (window.innerWidth > WINDOW_SIZES.xLarge) {
    return WINDOW_SIZE_TYPE_IDS.xLarge
  }

  return WINDOW_SIZE_TYPE_IDS.large
}

export const getPayerProductLink = (item = {}, username) => {
  const { id, productSlug, courseSessionId, orderToken, form } = item
  switch (form) {
    case SELLABLE_TYPE_IDS.course:
      return `/payer/s/${username}/courses/${productSlug}?course_session_id=${courseSessionId}`
    case SELLABLE_TYPE_IDS.bundle:
    case SELLABLE_TYPE_IDS.membership:
    case SELLABLE_TYPE_IDS.upsell:
      return `/payer/s/${username}/products/${id}`
    case SELLABLE_TYPE_IDS.eventTickets:
    case SELLABLE_TYPE_IDS.digital:
    case SELLABLE_TYPE_IDS.download:
    case SELLABLE_TYPE_IDS.licenseKey:
    case SELLABLE_TYPE_IDS.certificate:
      return `/s/${username}/payment/${orderToken}?success_url=skip`
    default:
      return PAYER_PRODUCTS_PATH
  }
}

export const sellableWithPeriod = (sellables, periodType) =>
  ascendingSort(sellables || [], 'id').find((item) => item.pricingPlanForm === periodType)
