import React, { useState } from 'react'
import classnames from 'classnames'

import { linkToImage } from 'utils/ssr.utils'

import cone from './icons/cone.png'
import frontendError from './icons/frontend-error.png'

import './error-boundary.scss'

const Fallback = (props) => {
  const { error, componentStack } = props
  const [detailsOpen, setDetailsOpen] = useState(false)

  const detailsIconClasses = classnames('fas', {
    'fa-caret-down': !detailsOpen,
    'fa-caret-up': detailsOpen,
  })

  return (
    <div className='elopage-error-boundary'>
      <div className='elopage-error-boundary__container'>
        <img className='elopage-error-boundary__error-image' src={linkToImage(frontendError)} />
        <div className='elopage-error-boundary__user-message'>
          <div>
            <img src={linkToImage(cone)} />
          </div>
          <div className='elopage-error-boundary__wrong-text-title'>{I18n.t('react.shared.notify.error')}</div>
          <div>
            <img src={linkToImage(cone)} />
          </div>
        </div>
        <div onClick={() => setDetailsOpen(!detailsOpen)} className='elopage-error-boundary__details-button'>
          {I18n.t('react.shared.details')}
          <i className={detailsIconClasses} />
        </div>
        {detailsOpen && (
          <div className='elopage-error-boundary__error-details'>
            <div>{error.toString()}</div>
            <div>{componentStack}</div>
          </div>
        )}
      </div>
    </div>
  )
}

const FallbackWrapper = (props) => <Fallback {...props} />

export default FallbackWrapper
