import { PRODUCT_TYPE_IDS } from './productsShared.constants'

export const SELLABLE_TYPE_IDS = {
  ...PRODUCT_TYPE_IDS,
  upsell: 'upsell',
}

export const WINDOW_SIZE_TYPE_IDS = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  xLarge: 'xLarge',
}

export const ITEMS_PER_PAGE = {
  small: {
    value: 6,
    label: '6',
  },
  medium: {
    value: 6,
    label: '6',
  },
  large: {
    value: 9,
    label: '9',
  },
  xLarge: {
    value: 12,
    label: '12',
  },
}

export const WINDOW_SIZES = {
  small: 576,
  medium: 768,
  large: 992,
  xLarge: 1700,
}

export const SELLABLE_FILTERS_LIST = ['sellableProductType', 'createdAt']
