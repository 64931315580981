(function(w, d) {
  var e, id, n, s;
  id = 'embedly-platform';
  n = 'script';
  if (!d.getElementById(id)) {
    w.embedly = w.embedly || function() {
      (w.embedly.q = w.embedly.q || []).push(arguments);
    };
    e = d.createElement(n);
    e.id = id;
    e.async = 1;
    e.src = ('https:' === document.location.protocol ? 'https' : 'http') + '://cdn.embedly.com/widgets/platform.js';
    s = d.getElementsByTagName(n)[0];
    s.parentNode.insertBefore(e, s);
  }
})(window, document);
