import { GET, POST, GET_API_URL } from 'utils/requests.utils'

const BASE_URL = '/payer/csv_logs'

export const fetchList = (data) => GET(BASE_URL, data)

export const createItem = (data) => POST(BASE_URL, data)

export const downloadCsv = (id) => GET_API_URL(`${BASE_URL}/${id}/download_csv`)

export const duplicateItem = (id) => POST(`${BASE_URL}/${id}/duplicate`)
