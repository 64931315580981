import React, { lazy, Suspense, Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

const ReloadPage = lazy(() => import('shared/components/ReloadPage'))
const PageNotFound = lazy(() => import('./screens/PageNotFound'))
const SellerResolver = lazy(() => import('./screens/sellers/SellerResolver'))

import {
  PAYER_SELLERS_PRODUCTS_DETAILS_PATH,
  PAYER_SELLERS_PRODUCTS_PATH,
  PAYER_SELLERS_SHOP_DETAILS_PATH,
  PAYER_SELLERS_SHOP_PATH,
  PAYER_PRODUCTS_PATH,
  PAYER_TRANSFERS_PATH,
  PAYER_ORDERS_PATH,
  PAYER_COURSE_SESSIONS_PATH,
  PAYER_MEMBERSHIP_SESSIONS_PATH,
  PAYER_PROFILE_PATH,
  PAYER_OVERVIEW_PATH,
  PAYER_RELOAD_PATH,
  PAYER_NOT_FOUND_PATH,
  PAYER_BANK_ACCOUNTS_PATH,
  PAYER_COMMUNITIES_PATH,
  PAYER_INVOICES_PATH,
  PAYER_CREDIT_MEMOS_PATH,
} from 'constants/routes/payer.constants'

import { cancelPendingRequests } from 'utils/request-cancelation.utils'
import LoadingFallback from 'ui/loading-fallback/LoadingFallback'
import PayerResolver from './screens/PayerResolver'

const Routes = (
  <Suspense fallback={<LoadingFallback type='payer' />}>
    <Switch>
      <Route exact path={PAYER_SELLERS_PRODUCTS_DETAILS_PATH} component={SellerResolver} />
      <Route exact path={PAYER_SELLERS_PRODUCTS_PATH} component={SellerResolver} />
      <Route exact path={PAYER_SELLERS_SHOP_DETAILS_PATH} component={SellerResolver} />
      <Route exact path={PAYER_SELLERS_SHOP_PATH} component={SellerResolver} />
      <Route exact path={PAYER_PRODUCTS_PATH} component={SellerResolver} />
      <Route exact path={PAYER_TRANSFERS_PATH} component={PayerResolver} />
      <Route exact path={PAYER_ORDERS_PATH} component={PayerResolver} />
      <Route exact path={PAYER_COURSE_SESSIONS_PATH} component={PayerResolver} />
      <Route exact path={PAYER_MEMBERSHIP_SESSIONS_PATH} component={PayerResolver} />
      <Route exact path={PAYER_INVOICES_PATH} component={PayerResolver} />
      <Route exact path={PAYER_CREDIT_MEMOS_PATH} component={PayerResolver} />
      <Route exact path={PAYER_PROFILE_PATH} component={PayerResolver} />
      <Route exact path={PAYER_BANK_ACCOUNTS_PATH} component={PayerResolver} />
      <Route exact path={PAYER_OVERVIEW_PATH} component={SellerResolver} />
      <Route exact path={PAYER_COMMUNITIES_PATH} component={PayerResolver} />
      <Route exact path={PAYER_RELOAD_PATH} component={ReloadPage} />
      <Route exact path={PAYER_NOT_FOUND_PATH} component={PageNotFound} />
    </Switch>
  </Suspense>
)

@withRouter
class RoutesWithCancelation extends Component {
  componentDidMount() {
    const { history } = this.props

    history.listen((location) => {
      cancelPendingRequests(location)
    })
  }

  render() {
    return Routes
  }
}

export default RoutesWithCancelation
