import { getCookies, setCookies } from 'libs/common/cookies'
import { apiClient } from 'utils/requests.utils'

import { createViewLogsApi } from '../api/viewLogs.api'

export class ViewLogsStore {
  storeName = 'ViewLogsStore'
  childApi

  constructor(root) {
    this.childApi = createViewLogsApi(root?.apiClient ?? apiClient)
  }

  trackViewLog = async (pms) => {
    await this.childApi.createItem({
      ...pms,
      clickerToken: this.getClickerToken(),
    })
  }

  getClickerToken() {
    return (
      getCookies('clicker_token') ||
      (setCookies('clicker_token', this.generateClickerToken()) && getCookies('clicker_token'))
    )
  }

  generateClickerToken() {
    return Math.random().toString(36).substr(2)
  }
}

export default new ViewLogsStore()
