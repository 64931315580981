import { observable, action, makeObservable } from 'mobx'

import { fetchQuiz, submitQuiz } from '../api/quizzes.api'

export class QuizzesStore {
  @observable loading = true
  @observable list = []
  @observable.ref item = {}

  @action
  fetchItem = async (courseSessionId, lessonId) => {
    this.loading = true
    const resp = await fetchQuiz(courseSessionId, lessonId)
    this.item = resp.data || {}
    this.loading = false
    return resp
  }

  @action
  submitQuiz = async (courseSessionId, lessonId, data) => {
    this.loading = true
    const resp = await submitQuiz(courseSessionId, lessonId, data)
    this.item = resp.data
    this.loading = false
    return resp
  }

  constructor() {
    makeObservable(this)
  }
}
