import { makeObservable } from 'mobx'

import {
  getAvailableCurrencies,
  getCurrencyId,
  getCurrencyKey,
  getCurrencyUnit,
  convertToPrice,
  getCurrencySignPosition,
} from 'utils/currencies.utils'
import { CURRENCY_SIGNS } from 'constants/currencies.constants'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { apiClient } from 'utils/requests.utils'

import SharedStore from './shared.store'

import { CurrenciesApi, createCurrenciesApi } from '../api/currencies.api'

export class CurrenciesStore extends SharedStore<any> {
  storeName = 'CurrenciesStore'
  declare childApi: CurrenciesApi

  constructor(root?: ShopRootStore) {
    super()
    makeObservable(this)

    this.childApi = createCurrenciesApi(root?.apiClient ?? apiClient)
  }

  getId = (key: string) => getCurrencyId(this.list, key)

  getKey = (id: number) => getCurrencyKey(this.list, id)

  getUnit = (id: number) => getCurrencyUnit(this.list, id)

  getCurrencySignPosition = (currencySign: CURRENCY_SIGNS) => getCurrencySignPosition(currencySign)

  convertToPrice = (value: string | number, currencyId: number | string, separator = '') =>
    convertToPrice(this.list, value || 0, currencyId, separator)

  filterAvailableCurrencies = ({
    paymentSystem,
    paymentForm,
    paymentProvider,
  }: Partial<{ paymentSystem: string; paymentForm: string; paymentProvider: string }> = {}) =>
    getAvailableCurrencies(this.list, paymentSystem, paymentForm, paymentProvider)
}

export default new CurrenciesStore()
