import { GET, POST } from 'utils/requests.utils'

const BASE_URL = '/payer/membership_sessions'

export const fetchList = (data) => GET(BASE_URL, data)

export const cancelItemSubscription = (id) => POST(`${BASE_URL}/${id}/cancel_subscription`)

export const continueItemSubscription = (id) => POST(`${BASE_URL}/${id}/continue_subscription`)

export const dripInToggle = (id) => POST(`${BASE_URL}/${id}/drip_in_toggle`)
