import type { PaginatedResponse, Response } from 'types/responses'
import { ApiClient } from 'utils/requests.utils'

const BASE_URL = '/common/currencies'

export interface Currency {
  id: number
  key: string
  unit: string
  currencyId: string
}

export const createCurrenciesApi = ({ GET }: ApiClient) => ({
  fetchList: (data): Promise<PaginatedResponse<Currency[]>> => GET(BASE_URL, data),
  fetchItem: (id, data): Promise<Response<Currency>> => GET(`${BASE_URL}/${id}`, data),
})

export type CurrenciesApi = ReturnType<typeof createCurrenciesApi>
