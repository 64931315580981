import React from 'react'
import { Helmet } from 'react-helmet'

import { getDefaultMetaTagsConfig, getMetaTags } from 'utils/metaTags.utils'

interface MetaTagsProps {
  data?: {
    title?: string
    description?: string
  }
  customData?: {
    site_name: string
  }
  sellerStore?: any
  pathname: string
}

export const MetaTags = ({ data, customData, sellerStore, pathname }: MetaTagsProps) => {
  const { item } = sellerStore

  const metaData = {
    url: `${item.protocol}://${item.host}${pathname}`,
    domain: `${item.protocol}://${item.host}`,
    ...data,
  }

  const metaTags = getMetaTags(getDefaultMetaTagsConfig(metaData, customData)) // TODO: ts - check, customData is for getMetaTags?

  return <Helmet htmlAttributes={{ lang: I18n.locale }}>{metaTags}</Helmet>
}
