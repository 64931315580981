import { GET, POST } from 'utils/requests.utils'

const BASE_URL = '/payer/course_sessions'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id, data) => GET(`${BASE_URL}/${id}`, data)

export const cancelItemSubscription = (id) => POST(`${BASE_URL}/${id}/cancel_subscription`)

export const continueItemSubscription = (id) => POST(`${BASE_URL}/${id}/continue_subscription`)

export const dripInToggle = (id) => POST(`${BASE_URL}/${id}/drip_in_toggle`)

export const resetCourse = (id) => POST(`${BASE_URL}/${id}/reset`)

export const resetLesson = (courseSessionId, lessonId) =>
  POST(`${BASE_URL}/${courseSessionId}/lessons/${lessonId}/reset`)
