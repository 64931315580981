import { makeObservable } from 'mobx'

import * as api from '../api/payoutSettings.api'
import SharedStore from './shared.store'

export class PayoutSettingsStore extends SharedStore {
  storeName = 'PayoutSettingsStore'
  childApi = api

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new PayoutSettingsStore()
