import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'

import * as api from '../api/invoices.api'
import { Invoice } from '../api/invoices.api'

export class InvoicesStore extends SharedStore<Invoice> {
  storeName = 'InvoicesStore'
  childApi = api

  constructor() {
    super()

    makeObservable(this)
  }
}
