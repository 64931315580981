import React, { useEffect } from 'react'
import * as Sentry from '@sentry/react'

import './error-boundary.scss'
import Fallback from './Fallback'

const IS_DEV = process.env.ENV === 'development'

const ErrorBoundary = ({ children }) => {
  useEffect(() => {
    if (IS_DEV) {
      return
    }
  }, [])

  return <Sentry.ErrorBoundary fallback={Fallback}>{children}</Sentry.ErrorBoundary>
}

const ErrorBoundaryExport = IS_DEV ? ErrorBoundary : Sentry.withProfiler(ErrorBoundary)

export default ErrorBoundaryExport
