import { GET, POST } from 'utils/requests.utils'

const BASE_URL = '/payer/orders'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id) => GET(`${BASE_URL}/${id}`)

export const cancelOrder = (orderId) => POST(`${BASE_URL}/${orderId}/cancel`)

export const continueOrder = (orderId) => POST(`${BASE_URL}/${orderId}/continue`)
