import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { Tooltip } from 'reactstrap'
import { Field } from 'formik'
import FTextField from 'ui/formik-form/FTextField'
import { TextField } from '@elo-kit/components/elo-ui/text-field/TextField'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import { isWindowEnv } from 'utils/env.utils'
import { passwordRules, validatePassword } from 'utils/validators-auth.utils'
import { MIN_PASSWORD_LENGTH, NUMBER_COUNT_IN_PASS, SPECIAL_CHARACTER_COUNT_IN_PASS } from 'constants/profile.constants'
import { getInputPlaceholder } from 'utils/text.utils'

import { POSITIONS } from '@elo-kit/constants/general.constants'

import './passwordComplexity.scss'

interface Props {
  password: string
  fieldClassName?: string
  fieldName?: string
  fieldLabel?: string
  fieldPlaceholder?: string
  fieldPrefix?: React.ReactNode
  onChange?: (any) => void
  isInvalid?: boolean
  fieldType?: 'TextField'
  required?: boolean
  screenSize?: string
  inputId?: string
}

const getLevels = (I18n): { name: string; translation: string }[] => [
  {
    name: 'very-weak',
    translation: I18n.t('react.shared.password_complexity.very_weak'),
  },
  {
    name: 'weak',
    translation: I18n.t('react.shared.password_complexity.weak'),
  },
  {
    name: 'good',
    translation: I18n.t('react.shared.password_complexity.good'),
  },
  {
    name: 'strong',
    translation: I18n.t('react.shared.password_complexity.strong'),
  },
]
const TOOLTIP_BREAKPOINT = 1400

export const PasswordFieldWithComplexity: React.FC<Props> = observer(function PasswordComplexity(props) {
  const {
    password = '',
    fieldClassName,
    fieldName = 'password',
    fieldLabel = '',
    fieldPlaceholder = '',
    fieldPrefix,
    fieldType,
    screenSize,
    ...restProps
  } = props
  const [isPasswordFocus, setIsPasswordFocus] = useState(false)
  const [mobileView, setMobileView] = useState(
    isWindowEnv() ? window.innerWidth <= TOOLTIP_BREAKPOINT : screenSize !== 'desktop'
  )
  const I18n = useI18n()
  const label = fieldLabel || I18n.t('react.app.form.pass')
  const placeholder =
    fieldPlaceholder || getInputPlaceholder(I18n.t('react.app.form.pass'), I18n.t('react.app.form.pass_placeholder'))
  const handleResize = () =>
    setMobileView(isWindowEnv() ? window.innerWidth <= TOOLTIP_BREAKPOINT : screenSize !== 'desktop')
  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const complexity = validatePassword(password, passwordRules)
  const passwordLevel = getLevels(I18n)[complexity.confirmedRules.length - 1]
  const complexityRules = complexity.confirmedRules.join(' ')

  const indicatorClasses = classNames('indicators', passwordLevel?.name, {
    'show-indicators': password.length || isPasswordFocus,
  })
  const toolTipClasses = classNames('password-tooltip-body', 'password-rule-list', complexityRules)
  const mobileRulesClasses = classNames('mobile-rule-list', 'password-rule-list', complexityRules)

  const getRuleList = (): React.ReactNode => (
    <>
      <div className='length'>
        {I18n.t('react.shared.password_complexity.pass_length', { length: MIN_PASSWORD_LENGTH })}
      </div>
      <div className='numbers'>
        {I18n.t('react.shared.password_complexity.number_in_pass', { number: NUMBER_COUNT_IN_PASS })}
      </div>
      <div className='symbols'>
        {I18n.t('react.shared.password_complexity.special_characters', { number: SPECIAL_CHARACTER_COUNT_IN_PASS })}
      </div>
      <div className='upperLowerCase'>{I18n.t('react.shared.password_complexity.upper_lower_case')} </div>
    </>
  )

  const getField = (type) => {
    if (type == 'TextField') {
      return (
        <TextField
          withHidePassword
          name={fieldName}
          label={label}
          placeholder={placeholder}
          type='password'
          onFocus={() => setIsPasswordFocus(true)}
          onBlur={() => setIsPasswordFocus(false)}
          className={fieldClassName}
          prefix={fieldPrefix}
          value={password}
          inputId='new-password-text-field'
          {...restProps}
        />
      )
    } else {
      return (
        <Field
          withHidePassword
          name={fieldName}
          label={label}
          placeholder={placeholder}
          type='password'
          component={FTextField}
          onFocus={() => setIsPasswordFocus(true)}
          handleBlur={() => setIsPasswordFocus(false)}
          className={fieldClassName}
          prefix={fieldPrefix}
          {...restProps}
        />
      )
    }
  }

  return (
    <div className='password-complexity'>
      {getField(fieldType)}
      <div id='passwordComplexity'>
        <div className={indicatorClasses}>
          <div className='indicator'></div>
          <div className='indicator'></div>
          <div className='indicator'></div>
          <div className='indicator'></div>
          <div id='weakLabel' className='level-label'>
            {passwordLevel?.translation}
          </div>
        </div>
        {isPasswordFocus && <div className={mobileRulesClasses}>{getRuleList()}</div>}
      </div>
      <Tooltip
        isOpen={isPasswordFocus}
        toggle={() => {}}
        target='weakLabel'
        placement={mobileView ? POSITIONS.bottom : POSITIONS.right}
        innerClassName={toolTipClasses}
        className='password-tooltip-container'
      >
        {getRuleList()}
      </Tooltip>
    </div>
  )
})
