import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/orderVatChange.api'

export class OrderVatChangeStore extends SharedStore<any> {
  storeName = 'OrderVatChangeStore'
  childApi = api

  reject = async (orderId) => {
    this.toggleLoading(true)
    await api.rejectVatChange(orderId)
    this.toggleLoading(false)
  }

  accept = async (orderId) => {
    this.toggleLoading(true)
    await api.acceptVatChange(orderId)
    this.toggleLoading(false)
  }
}
