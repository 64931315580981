import React, { Fragment, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router, useHistory } from 'react-router-dom'
import { observer, Provider } from 'mobx-react'
import { get } from 'utils/lodash.utils'
import { toJS } from 'mobx'
import userflow from 'userflow.js'

import 'libs/payer' // order import is important (I18n should be always on the top)
import 'libs/common/analytics'

import '../../../styles/payer'
import { initSentry } from 'libs/common/sentry'
import { setCookies, getCookies } from 'libs/common/cookies'
import { ACTIVE_PROFILES } from 'constants/profile.constants'
import { ELOPAGE_COOKIEBOT_CONFIG, USERFLOW_INIT_TOKEN, USERFLOW_LOCALE_CODE } from 'constants/general.constants'

import ErrorBoundary from 'ui/error-boundary/ErrorBoundary'

import { handleInitCabinetFetching } from 'utils/requests.utils'
import { profile } from 'utils/profileHelper.utils'
import { appendScript } from 'utils/dom.utils'
import { getCookiebotScript } from 'utils/cookiesConsents.utils'
import { CUSTOM_CSS_FEATURE_FLAG, CUSTOM_CSS_CONFIG_FIELD_NAME } from 'constants/themes.constants'
import { getElopageConfig } from 'utils/elopageConfig.utils'

import { Page } from 'shared/components/side-menu/Page'
import { AdminMode } from 'shared/components/admin-mode/AdminMode'
import ApiBranch from 'shared/components/api-branch/ApiBranch'
import { MetaTags } from 'shared/components/MetaTags'
import { BecomeSellerModal } from 'shared/components/side-menu/profile-switching/become-seller-modal/BecomeSellerModal'
import ChangePasswordModal from 'shared/screens/profile/ChangePasswordModal'

import { isEmpty } from '@elo-kit/utils/validators.utils'
import { I18nProvider, LoadingMask } from '@elo-kit/components'

import { ToastContainer } from 'react-toastify'
import Routes from './routes'
import { usePayerStore } from './hooks/use-payer-stores'

profile.setProfileType(ACTIVE_PROFILES.payer)

initSentry()

const PayerMetaTags = ({ sellerStore }) => {
  const history = useHistory()
  return <MetaTags pathname={history.location.pathname} sellerStore={sellerStore} />
}

const PayerApp = observer(function App() {
  const stores = usePayerStore()
  const { userStore, payerStore, currenciesStore, sellersStore, sellerStore } = stores
  const env = getElopageConfig('env')
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false)
  const toggleChangePasswordModal = () => setOpenChangePasswordModal(!openChangePasswordModal)

  const setupPassword = (user) => {
    if (user.noPassword && !getCookies('setup_password_shown')) {
      toggleChangePasswordModal()
      setCookies('setup_password_shown', true)
    }
  }

  useEffect(() => {
    handleInitCabinetFetching(userStore, payerStore)
    currenciesStore.fetchFullList({ sortDir: 'asc' })
    const { src, attributes } = getCookiebotScript(
      ELOPAGE_COOKIEBOT_CONFIG.cookiebotId,
      ELOPAGE_COOKIEBOT_CONFIG.cookiebotMode
    )
    appendScript(src, attributes, false)
  }, [])

  useEffect(() => {
    fetchUserFlowData()
  }, [userStore.item.id])

  const fetchUserFlowData = async () => {
    userflow.init(USERFLOW_INIT_TOKEN[env])
    const { item: user } = userStore

    if (user.id) {
      await userflow.identify(user.id, {
        payer_id: user.id,
        name: user.sellerUsername,
        email: user.email,
        locale_code: USERFLOW_LOCALE_CODE[user.locale],
        first_time_signed_in_user: user.signInCount === 1,
      })
    }
  }

  const { item } = payerStore
  const { isAppActive } = sellersStore
  const { toggleBecomeSellerModal, becomeSellerModalOpen, item: user } = userStore
  if (!isEmpty(toJS(user))) {
    setupPassword(user)
  }

  const isCustomCssAppActive = isAppActive(CUSTOM_CSS_FEATURE_FLAG)
  const customCss = get(stores, 'membershipThemesStore.item.prefs', {})

  return (
    <I18nProvider>
      <Provider {...stores}>
        <Router>
          <Fragment>
            <PayerMetaTags sellerStore={sellerStore} />
            {!item.id ? (
              <LoadingMask light />
            ) : (
              <Page>
                <Routes />
                <AdminMode userEmail={user.email} />
                <ApiBranch />

                <BecomeSellerModal
                  isOpen={becomeSellerModalOpen}
                  toggle={() => toggleBecomeSellerModal(true, false)}
                  userStore={userStore}
                />

                <ChangePasswordModal
                  isOpen={openChangePasswordModal}
                  toggle={toggleChangePasswordModal}
                  userStore={userStore}
                />
              </Page>
            )}

            {isCustomCssAppActive && customCss[CUSTOM_CSS_CONFIG_FIELD_NAME] && (
              <style
                dangerouslySetInnerHTML={{
                  __html: customCss[CUSTOM_CSS_CONFIG_FIELD_NAME],
                }}
              />
            )}
          </Fragment>
        </Router>

        <ToastContainer />
      </Provider>
    </I18nProvider>
  )
})

const root = createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <PayerApp />
  </ErrorBoundary>
  // </React.StrictMode>
)
